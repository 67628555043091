<template>
  <b-card
    no-body
  >

    <b-form>
      <b-row>

        <b-col
          sm="12"
          class="d-flex align-items-center mb-2"
        >
          <feather-icon
            icon="UserIcon"
            size="16"
          />
          <h5 class="mb-0 ml-75 font-weight-normal">
            User Information
          </h5>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="First Name"
          >
            <b-form-input
              v-model="userData.first_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Last Name"
          >
            <b-form-input
              v-model="userData.last_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Email"
          >
            <b-form-input
              v-model="userData.email"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Gender"
          >
            <div
              class="d-flex"
            >
              <b-form-radio
                v-model="userData.extra_user_info.gender"
                name="some-radios"
                value="Male"
              >
                Male
              </b-form-radio>
              <b-form-radio
                v-model="userData.extra_user_info.gender"
                name="some-radios"
                value="Female"
                class="ml-2"
              >
                Female
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Phone Number"
          >
            <b-form-input
              v-model="userData.phone"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Send Notification Emails"
          >
            <b-form-checkbox
              v-model="userData.is_mailable"
              class="custom-control-primary"
            >
              true
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="handleForm"
          >
            Save Сhanges
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
  BButton, BForm, BFormGroup, BFormInput, BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BButton,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleForm() {
      this.$emit('updateUserInfo')
    },
  },
}
</script>

<style>

</style>
