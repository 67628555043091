<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        User Settings
      </h5>
    </b-card-header>

    <b-card-body>

      <b-form-group
        label="Role:"
        class="mb-2"
      >
        <b-form-select
          v-model="userData.role.display_name"
          :options="options"
        />
      </b-form-group>

      <p>Last Login: {{ userData.last_login }}</p>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
  BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        { value: null, text: 'Please select a role' },
        { value: 'Admin', text: 'Admin' },
      ],
    }
  },
}
</script>

<style>

</style>
