<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-users'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row
        class="d-flex align-items-stretch"
      >
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>

        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-card>
        <user-view-user-info
          :user-data="userData"
          @updateUserInfo="updUserInfo"
        />
      </b-card>

      <!-- <b-row
        class="d-flex align-items-stretch"
      >
        <b-col
          cols="12"
          md="6"
        >
          <UserViewUserPermission user-data="userData" />
        </b-col>
      </b-row>-->

    </template>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import userStoreModule from '@/views/admin/users/usersStoreModule'
import UserViewUserInfoCard from '@/views/admin/users/user-profile/UserViewUserInfoCard.vue'
import UserViewUserPlanCard from '@/views/admin/users/user-profile/UserViewUserPlanCard.vue'
import UserViewUserInfo from '@/views/admin/users/user-profile/UserViewUserInfo.vue'
import useUserProfile from '@/views/admin/users/user-profile/useUsersProfile'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfo,
    UserViewUserInfoCard,
    UserViewUserPlanCard,

  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      updateUser,
    } = useUserProfile()

    store.dispatch('app-users/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
      updateUser,
    }
  },
  methods: {
    updUserInfo() {
      this.updateUser(this.userData)
    },
  },
}
</script>

<style>

</style>
