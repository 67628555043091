import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserProfile() {
  // Use toast
  const toast = useToast()

  const updateUser = queryParams => axios
    .put(`/auth/users/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'User was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating user',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const permissionsData = [
    {
      module: 'Admin',
      read: true,
      write: false,
      create: false,
      delete: false,
    },
    {
      module: 'Staff',
      read: false,
      write: true,
      create: false,
      delete: false,
    },
    {
      module: 'Author',
      read: true,
      write: false,
      create: true,
      delete: false,
    },
    {
      module: 'Contributor',
      read: false,
      write: false,
      create: false,
      delete: false,
    },
    {
      module: 'User',
      read: false,
      write: false,
      create: false,
      delete: true,
    },
  ]

  return {
    permissionsData,
    updateUser,
  }
}
